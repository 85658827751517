<template>
  <div class="card" style="min-height: 130px; margin-bottom: 15px">
    <div class="card-header">
      <router-link
        to="/groups"
        class="btn-close float-end"
        aria-label="Close"
        tag="button"
      ></router-link>

      <h3>
        {{ name }} <small class="text-muted">{{ uid }}</small>
      </h3>

      <ul class="nav nav-tabs card-header-tabs">
        <li class="nav-item">
          <span
            class="btn nav-link"
            :class="{ active: activeTab === 'emails' }"
            @click="switchTab('emails')"
            href="#"
            >Email addresses</span
          >
        </li>
        <li class="nav-item">
          <span
            class="btn nav-link"
            :class="{ active: activeTab === 'password' }"
            @click="switchTab('password')"
            href="#"
            >Password</span
          >
        </li>
        <li class="nav-item">
          <span
            class="btn nav-link text-danger"
            :class="{ active: activeTab === 'danger' }"
            @click="switchTab('danger')"
            href="#"
            >Danger zone</span
          >
        </li>
      </ul>
    </div>

    <div v-if="activeTab === 'emails'">
      <div v-if="emailAddresses === null" class="m-3">
        <span class="spinner-border" role="status" aria-hidden="true"></span>
      </div>
      <div v-else>
        <ul
          v-if="Object.keys(emailAddresses).length > 0"
          class="list-group list-group-flush"
        >
          <li
            v-for="(isExisting, email) in emailAddresses"
            :key="email"
            class="list-group-item"
          >
            <div class="input-group">
              <span class="input-group-text" id="basic-addon1">@</span>
              <input
                type="text"
                class="form-control"
                :class="{ 'is-invalid': !isExisting }"
                readonly
                :value="email"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
              <button
                class="btn btn-outline-secondary"
                type="button"
                @click="copyEmail(email)"
              >
                📋
              </button>
              <div v-if="!isExisting" class="invalid-feedback">
                This email address is not registered in hsadmin!
              </div>
            </div>
          </li>
        </ul>
        <p v-else style="margin: 19px auto; text-align: center">
          <i>∅ no email addresses defined</i>
        </p>
      </div>
    </div>
    <div class="card-body" v-if="activeTab === 'password'">
      <form v-on:submit.prevent="onChangePassword">
        <fieldset :disabled="isChangingPassword">
          <label for="newPassword" class="form-label">New password</label>
          <transition name="fade">
            <span v-if="passwordChangeSuccess" class="badge badge-success"
              >Success</span
            >
          </transition>
          <transition name="fade">
            <span v-if="passwordChangeFail" class="badge badge-danger"
              >Failed!</span
            >
          </transition>

          <div class="input-group">
            <button
              class="btn btn-outline-secondary"
              type="button"
              @click="showPassword ^= true"
            >
              <span v-if="showPassword">🙈</span>
              <span v-else>🙊</span>
            </button>
            <input
              v-if="showPassword"
              type="text"
              class="form-control"
              id="newPassword"
              v-model="newPassword"
              aria-describedby="passwordHelpBlock"
            />
            <input
              v-else
              type="password"
              class="form-control"
              id="newPassword"
              v-model="newPassword"
              aria-describedby="passwordHelpBlock"
            />
            <button class="btn btn-outline-secondary" type="submit">
              Change
            </button>
          </div>

          <h4 class="text-muted mt-3">How to choose a good password?</h4>
          <p>As of today (2021) there are three approved strategies:</p>

          <ul>
            <li>
              Choose a reasonably complex password using multiple character
              classes (upper-case, lower-case, special characters). Downside:
              They are hard to remember for users and potentionally lead to
              passwords being reused across different services.
            </li>
            <li>
              Choose a long password that consists of a personal sentence which
              is hard to break for machines and easy to remember for users
            </li>
            <li>
              Just for the sake of completeness: Choose a second factor to get
              the best possible security. This is not yet supported by the ECG
              services. But it's on our radar.
            </li>
          </ul>
          <p>
            Although currently not technically prevented it's not allowed to
            reuse a previous password
          </p>
          <p>Furthermore we recommend using a password manager like KeePass.</p>
          <h6>In the case of problems</h6>
          <p>
            You can find
            <a href="https://wiki.ecogood.org/x/DYQjB" target="_blank">
              a detailed explanation of the password reset process in the
              wiki</a
            >.
          </p>
        </fieldset>
      </form>
    </div>
    <div class="card-body" v-if="activeTab === 'danger'">
      <p class="lead">
        The following actions can seriously harm the user account!
      </p>
      <hr class="my-4" />

      <form action="remove" method="POST" @submit.prevent="onRemove">
        <button type="submit" class="btn btn-danger">Delete Account</button>
      </form>
    </div>
  </div>
</template>

<script>
"use strict";

import axios from "axios";

export default {
  data() {
    return {
      activeTab: "emails",

      uid: this.$route.params.uid,
      name: null,
      emailAddresses: null,
      showPassword: false,
      newPassword: "",
      passwordChangeSuccess: null,
      passwordChangeFail: null,

      isChangingPassword: false,
    };
  },
  methods: {
    onChangePassword: function () {
      this.isChangingPassword = true;

      const formData = new FormData();
      formData.append("password", this.newPassword);

      axios({
        method: "post",
        url: `groups/${this.uid}/password`,
        data: this.newPassword,
        headers: {
          "Content-Type": "text/plain",
        },
      }).then(
        () => {
          this.isChangingPassword = false;
          this.passwordChangeSuccess = true;
          this.passwordChangeFail = false;
          this.newPassword = "";

          setTimeout(
            function () {
              this.passwordChangeSuccess = false;
            }.bind(this),
            3000
          );
        },
        (response) => {
          this.isChangingPassword = false;
          this.passwordChangeFail = true;
          this.passwordChangeSuccess = false;

          setTimeout(
            function () {
              this.passwordChangeFail = false;
            }.bind(this),
            3000
          );

          console.error("failed to change password", response);
        }
      );
    },
    copyEmail: function (emailAddress) {
      navigator.clipboard.writeText(emailAddress).then(
        function () {},
        function () {
          console.error("Unable to write to clipboard!", emailAddress);
        }
      );
    },
    load: function () {
      this.name = "…";
      this.emailAddresses = null;
      axios.get(`groups/${this.uid}`).then((response) => {
        this.name = response.data.name;
        this.emailAddresses = response.data.emails;
      });
    },
    switchTab: function (target) {
      this.activeTab = target;
    },
    onRemove: function () {
      if (confirm(`Are you sure to delete '${this.name}' (${this.uid})?`)) {
        axios.delete(`users/${this.uid}`).then(
          () => this.$emit("group-deleted", this.uid),
          (response) => console.error("failed to remove user", response)
        );
      }
    },
  },
  mounted() {
    this.load();
  },
  beforeRouteUpdate(to, from, next) {
    this.uid = to.params.uid;
    this.load();
    next();
  },
};
</script>
