<template>
  <div id="group-create-form">
    <form
      method="POST"
      class="align-middle col-md-8"
      @submit.prevent="onSubmit"
    >
      <fieldset :disabled="isCreating">
        <div class="row">
          <div class="col">
            <label for="name" class="form-label">Common Name</label>
            <div class="form-group mb-3">
              <input
                id="name"
                type="text"
                v-model="payload.name"
                class="form-control"
                minlength="2"
                required="true"
              />
            </div>
            <label for="uid" class="form-label"
              >UID (short name, e.g. 'belgium' for ECG Belgium)</label
            >
            <div class="from-group mb-3">
              <input
                id="uid"
                type="text"
                autocapitalize="none"
                v-model="payload.uid"
                class="form-control"
                minlength="2"
                required="true"
              />
            </div>
            <label for="email" class="form-label"
              >Requested email address</label
            >
            <div class="input-group mb-3">
              <input
                id="email"
                type="text"
                autocapitalize="none"
                v-model="payload.emailPrefix"
                class="form-control"
                minlength="2"
                required="true"
                placeholder="my-group"
              />
              <span class="input-group-text" id="basic-addon2"
                >@ecogood.org</span
              >
            </div>
          </div>
          <div class="col">
            <label for="authorityEmail" class="form-label"
              >Email address of person in charge</label
            >
            <div class="form-group mb-3">
              <input
                id="authorityEmail"
                type="email"
                class="form-control"
                minlength="6"
                required="true"
                v-model="payload.authorityEmail"
                placeholder="user@ecogood.org"
              />
              <small class="form-text text-muted"
                >Every group requires one person within the ECG to act as person
                of charge … at least for the account.</small
              >
            </div>
            <label for="password" class="form-label">Password</label>
            <div class="form-group mb-3">
              <input
                type="password"
                v-model="payload.password"
                id="password"
                class="form-control"
                minlength="6"
                required="true"
                autocomplete="new-password"
              />
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-primary">
          <span v-if="!isCreating">Create</span>
          <span v-else>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Loading...
          </span>
        </button>
      </fieldset>
    </form>

    <div v-if="requestError" class="alert alert-danger" role="alert">
      {{ requestError }}
    </div>
    <hr />
  </div>
</template>


<script>
"use strict";

import axios from "axios";

export default {
  data() {
    return {
      payload: {
        name: "",
        uid: "",
        emailPrefix: "",
        authorityEmail: "",
        password: "",
      },
      isCreating: false,
      requestError: null,
    };
  },
  watch: {
    "payload.uid": function () {
      if (this.payload.uid !== "") {
        this.$emit("uid-generated", this.payload.uid);
      }
    },
  },
  methods: {
    onSubmit: function () {
      this.isCreating = true;

      axios
        .post("groups", this.payload)
        .then(
          () => {
            this.$emit("group-created", this.payload.uid, this.payload.name);
            this.requestError = null;

            //                            this.clearForm();
          },
          (error) => {
            this.requestError =
              error.response.status === 400
                ? error.response.data
                : "Unknown error!";

            console.error("group creation failed", error.message);
          }
        )
        .finally(() => {
          this.isCreating = false;
        });
    },
    clearForm: function () {
      this.displayName = null;
      this.ldapGroups = null;
      this.impPayload = null;
      this.emailLoginName = null;
    },
  },
};
</script>
