<template>
  <div>
    <h1>
      <button
        type="button"
        v-if="showCreate"
        @click="showCreate = false"
        class="btn btn-circle btn-outline-secondary"
      >
        <strong>⨯</strong>
      </button>
      <button
        type="button"
        v-else
        @click="showCreate = true"
        class="btn btn-circle btn-outline-secondary"
      >
        <strong>+</strong>
      </button>
      User Accounts
      <span class="badge bg-secondary">{{ userCountFormatted }}</span>
    </h1>

    <router-view @user-deleted="onUserDelete"></router-view>

    <user-create
      v-if="showCreate"
      @uid-generated="onUidGenerated"
      @user-created="onUserCreate"
    ></user-create>
    <user-list
      ref="userList"
      @users-loaded="userCount = $event"
      @user-selected="onUserSelect"
    ></user-list>
  </div>
</template>

<script>
"use strict";

import UserCreate from "./UserCreate";
import UserList from "./UserList";

export default {
  components: {
    UserCreate,
    UserList,
  },
  data: function () {
    return {
      v1: "hello 3",
      userCount: null,
      showCreate: false,
    };
  },
  computed: {
    userCountFormatted() {
      return this.userCount === null
        ? "…"
        : new Intl.NumberFormat().format(this.userCount);
    },
  },
  methods: {
    onUserDelete: function (uid) {
      this.$refs.userList.removeUser(uid);
      this.userCount--;

      this.$router.push("/");
    },
    onUserCreate: function (uid, displayName) {
      this.showCreate = false;
      this.$refs.userList.searchTerm = "";
      this.$refs.userList.addUser(uid, displayName);
      this.userCount++;

      this.$router.push(`/users/${uid}`);
    },
    onUserSelect: () => window.scrollTo(0, 0),
    onUidGenerated: function (uid) {
      this.$refs.userList.searchTerm = uid;
    },
  },
};
</script>