<template>
  <div>
    <div v-if="loadError" class="alert alert-danger" role="alert">
      💥 Failed to load the user entries from the backend:
      <strong>{{ loadError }}</strong>
    </div>
    <div v-else>
      <form @submit.prevent class="search">
        <div class="form-group">
          <label for="searchTerm">Search for user name or UID:</label>
          <input
            type="text"
            v-model="searchTerm"
            class="form-control"
            id="searchTerm"
            placeholder="e.g. Hans"
            :autofocus="'autofocus'"
            aria-describedby="groupHelp"
          />
        </div>
      </form>

      <div class="form-check form-switch">
        <input
          class="form-check-input"
          type="checkbox"
          role="switch"
          id="switchLocked"
          v-model="filterLocked"
        />
        <label class="form-check-label" for="switchLocked"
          ><i class="bi bi-lock"></i> Only locked</label
        >
      </div>

      <div v-if="users === null" class="m-3">
        <span class="spinner-border" role="status" aria-hidden="true"></span>
      </div>
      <template v-else>
        <div v-if="users.length === 0">∅</div>
        <table class="table table-hover table-striped mt-1">
          <thead class="sticky-top">
            <tr>
              <th scope="col">uid</th>
              <th scope="col">Name</th>
            </tr>
          </thead>
          <tbody>
            <tr
              style="cursor: pointer"
              v-for="user in users"
              :key="user.uid"
              @click="showDetails(user.uid)"
            >
              <td>
                {{ user.uid }}
                <span v-if="user.isLocked" class="badge bg-primary rounded-pill"
                  ><i class="bi bi-lock"></i
                ></span>
              </td>
              <td style="white-space: pre">{{ user.name }}</td>
            </tr>
          </tbody>
        </table>
      </template>
    </div>
  </div>
</template>

<style scoped>
.search {
  margin-bottom: 10px;
}
</style>

<script>
"use strict";
import axios from "axios";

export default {
  data() {
    return {
      loadError: null,
      searchTerm: "",
      usersAll: null,
      filterLocked: false,
    };
  },
  computed: {
    searchTermLowerCase: function () {
      return this.searchTerm.toLowerCase();
    },
    users: function () {
      if (this.usersAll === null) {
        return null;
      } else {
        let result = [];

        for (let user of this.usersAll) {
          const found =
            user.uid.toLowerCase().includes(this.searchTermLowerCase) ||
            user.uid.toLowerCase().includes(this.searchTermLowerCase);
          const lockedFiltered = !this.filterLocked || user.isLocked;
          if (found && lockedFiltered) {
            result.push({
              uid: user.uid,
              name: user.displayName,
              isLocked: user.isLocked,
            });
          }
        }

        return result;
      }
    },
  },
  methods: {
    addUser: function (uid, displayName) {
      this.usersAll.push({ uid: uid, displayName: displayName });
    },
    removeUser: function (uid) {
      this.usersAll = this.usersAll.filter((i) => i.uid.localeCompare(uid));
    },
    showDetails: function (uid) {
      this.$router.push({ path: `/users/${uid}` });
      this.$emit("user-selected", uid);
    },
    load: function () {
      axios.get("users").then(
        (response) => {
          // Result: array of objects: {uid, displayName, isLocked}
          this.usersAll = response.data.sort((a, b) =>
            a.displayName.localeCompare(b.displayName)
          );
          this.$emit("users-loaded", Object.keys(response.data).length);
        },
        (error) => {
          this.loadError = error.message;
        }
      );
    },
  },
  mounted() {
    this.load();
  },
};
</script>
