<template>
  <div>
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'users' }" tag="a">
          👪 Users</router-link
        >
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'groups' }" tag="a">
          📧 Mailboxes</router-link
        >
      </li>
    </ul>

    <router-view></router-view>
  </div>
</template>

<script>
"use strict";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import axios from "axios";

axios.defaults.baseURL =
  process.env.NODE_ENV === "development" ? "//localhost:8090" : "/api";

// Add an authentication request interceptor
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

import asvcRouter from "./router";

// Catch all unauthorized requests, due to a wrong token, probably when outdated.
axios.interceptors.response.use(
  (response) => response,
  function (error) {
    let containsResponse = typeof error.response !== "undefined";
    if (containsResponse && error.response.status === 401) {
      localStorage.removeItem("token");
      asvcRouter.push({
        name: "login",
        params: { nextUrl: asvcRouter.currentRoute.fullPath },
      });
      return Promise.resolve(error);
    } else {
      return Promise.reject(error);
    }
  }
);

export default {};
</script>

<style>
body.container {
  max-width: 1140px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.btn-circle {
  width: 30px;
  height: 30px;
  margin: 0 10px;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

/* make list group items leaner */
.table tr {
  line-height: 0.8;
}
.table-striped > tbody > tr,
.table-striped > tbody > tr:nth-of-type(2n + 1) {
  color: #007bff;
}
.table tbody tr:hover {
  text-decoration: underline;
  color: #0a58ca;
}
.table .sticky-top {
  background-color: #fff;
}
</style>
