<template>
  <div class="card">
    <div class="card-header">
      <router-link
        to="/users"
        class="btn-close float-end"
        aria-label="Close"
        tag="button"
      ></router-link>

      <h3>
        {{ displayName }} <small class="text-muted">{{ uid }}</small>
      </h3>

      <ul class="nav nav-tabs card-header-tabs">
        <li class="nav-item">
          <span
            class="btn nav-link"
            :class="{ active: activeTab === 'groups' }"
            @click="switchTab('groups')"
            href="#"
            >Details</span
          >
        </li>
        <li class="nav-item">
          <span
            class="btn nav-link"
            :class="{ active: activeTab === 'services' }"
            @click="switchTab('services')"
            href="#"
            >Services</span
          >
        </li>
        <li class="nav-item">
          <span
            class="btn nav-link text-danger"
            :class="{ active: activeTab === 'danger' }"
            @click="switchTab('danger')"
            href="#"
            >Danger zone</span
          >
        </li>
      </ul>
    </div>

    <div class="card-body">
      <div v-if="activeTab === 'groups'">
        <div class="mb-3 row">
          <label for="emailEcg" class="col-3 col-form-label"
            >Email address ECG</label
          >
          <div class="col-9">
            <input
              type="text"
              readonly
              class="form-control"
              id="emailEcg"
              :value="emailAddress"
            />
          </div>
        </div>
        <div class="mb-3 row">
          <label for="emailPrivate" class="col-3 col-form-label"
            >Email address private</label
          >
          <div class="col-9">
            <input
              type="text"
              readonly
              class="form-control"
              id="emailPrivate"
              :value="emailAddressPrivate"
            />
          </div>
        </div>
        <div class="mb-3 row">
          <label for="ldapUuid" class="col-3 col-form-label">LDAP UUID</label>
          <div class="col-9">
            <input
              type="text"
              readonly
              class="form-control"
              id="ldapUuid"
              :value="uuid"
            />
          </div>
        </div>
        <div class="mb-3 row">
          <label for="passwordStrength" class="col-3 col-form-label"
            >Password is strong</label
          >
          <div class="col-9">
            <ul v-if="pwdChangeTimes" class="list-group list-group-flush">
              <li
                v-for="changeTime in pwdChangeTimes"
                :key="changeTime"
                class="list-group-item"
              >
                <i
                  class="bi"
                  :class="
                    isStrong(changeTime)
                      ? 'bi-shield-fill-check'
                      : 'bi-shield-x'
                  "
                ></i>
                &nbsp;
                {{ changeTime.toLocaleString() }}
              </li>
            </ul>
          </div>
        </div>

        <h4>Groups</h4>
        <div v-if="ldapGroups === null" class="mt-3">
          <span class="spinner-border" role="status" aria-hidden="true"></span>
        </div>
        <template v-else>
          <div v-if="ldapGroups.length === 0">∅</div>
          <ul v-else>
            <li v-for="name in ldapGroups" :key="name">{{ name }}</li>
          </ul>
        </template>
      </div>

      <div v-if="activeTab === 'services'">
        <ul>
          <li><a :href="'/#/' + uid" target="_blank">Password reset</a></li>
          <li>
            <a
              :href="'https://webmail.ecogood.org/?_user=' + uid"
              target="_blank"
              >Webmail Login</a
            >
          </li>
        </ul>
      </div>
      <div v-if="activeTab === 'danger'">
        <p class="lead">
          The following actions can seriously harm the user account!
        </p>
        <hr class="my-4" />

        <div v-if="isImpersonated">
          <form
            action="imp-remove"
            method="POST"
            v-on:submit.prevent="onRestore"
          >
            <button type="submit" class="btn btn-primary">
              Restore password
            </button>
          </form>

          <div v-if="impPassword != null">
            Temporary password created:
            <div class="input-group flex-nowrap">
              <div class="input-group-prepend" @click="showPassword ^= true">
                <span
                  v-if="showPassword"
                  class="btn input-group-text"
                  id="addon-wrapping"
                  >🙈</span
                >
                <span v-else class="btn input-group-text" id="addon-wrapping"
                  >🙊</span
                >
              </div>
              <input
                v-if="showPassword"
                type="text"
                class="form-control"
                readonly
                :value="impPassword"
              />
              <input
                v-else
                type="password"
                class="form-control"
                readonly
                :value="impPassword"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary"
                  type="button"
                  @click="copyPassword"
                >
                  📋
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <form
            action="imp-create"
            method="POST"
            @submit.prevent="onImpersonate"
            title="This only affects the LDAP account!"
          >
            <button type="submit" class="btn btn-warning">Impersonate</button>
          </form>
        </div>

        <hr class="my-4" />

        <form action="remove" method="POST" @submit.prevent="onRemove">
          <button type="submit" class="btn btn-danger">Delete Account</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
"use strict";

import axios from "axios";

export default {
  data() {
    return {
      uid: this.$route.params.uid,

      displayName: null,
      ldapGroups: null,
      isImpersonated: false,
      impPassword: null,
      pwdChangeTimes: [],
      emailAddress: null,
      emailAddressPrivate: null,
      uuid: null,

      showPassword: false,
      activeTab: "groups",
    };
  },
  methods: {
    load: function () {
      this.displayName = "…";
      this.ldapGroups =
        this.isImpersonated =
        this.impPassword =
        this.emailAddress =
        this.emailAddressPrivate =
          null;
      this.pwdChangeTimes = [];

      axios.get(`users/${this.uid}`).then((response) => {
        this.displayName = response.data.displayName;
        this.ldapGroups = response.data.ldapGroups;
        this.isImpersonated = response.data.isImpersonated;
        this.emailAddress = response.data.emailAddress;
        this.emailAddressPrivate = response.data.emailAddressPrivate;
        this.uuid = response.data.uuid;
        if ("pwdChangeTimes" in response.data) {
          this.pwdChangeTimes = response.data.pwdChangeTimes.map(
            (t) => new Date(t)
          );
        }
      });
    },
    switchTab: function (target) {
      this.activeTab = target;
    },
    onImpersonate: function () {
      this.impPassword = this.createPassword(14);

      axios.post(`users/${this.uid}/imp.create`, this.impPassword).then(
        () => {
          this.isImpersonated = true;
        },
        (response) => {
          console.error("failed to impersonate", response);
        }
      );
    },
    createPassword: function (length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;

      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      return result;
    },
    onRestore: function () {
      axios.post(`users/${this.uid}/imp.remove`).then(
        () => {
          this.isImpersonated = false;
          this.impPassword = null;
        },
        (response) => {
          console.error("failed to restore impersonate", response);
        }
      );
    },
    onRemove: function () {
      if (
        confirm(`Are you sure to delete '${this.displayName}' (${this.uid})?`)
      ) {
        axios.delete(`users/${this.uid}`).then(
          () => {
            this.$emit("user-deleted", this.uid);
          },
          (response) => {
            console.error("failed to remove user", response);
          }
        );
      }
    },
    copyPassword: function () {
      navigator.clipboard.writeText(this.impPassword).then(
        function () {},
        function () {
          console.error("Unable to write to clipboard!", this.impPassword);
        }
      );
    },
    isStrong: function (changeTime) {
      return changeTime.getTime() > new Date("2021-04-27").getTime();
    },
  },
  mounted() {
    this.load();
  },
  beforeRouteUpdate(to, from, next) {
    this.uid = to.params.uid;
    this.load();
    next();
  },
};
</script>

<style>
.bi-shield-fill-check {
  color: darkgreen;
}
.bi-shield-x {
  color: darkred;
}
</style>