"use strict";

import { createRouter, createWebHashHistory } from 'vue-router';
import Login from './Login';
import User from './User';
import UserDetails from './UserDetails';
import Group from './Group';
import GroupDetails from './GroupDetails';

const routes = [
    {name: 'login', path: '/login', component: Login},
    {name: 'users', path: '/users', alias: '/', component: User, children: [
            {name: 'user', path: '/users/:uid', component: UserDetails}
        ]},
    {name: 'groups', path: '/groups', component: Group, children: [
            {name: 'group', path: '/groups/:uid', component: GroupDetails}
        ]}
];

const asvcRouter = createRouter({
    history: createWebHashHistory(),
    routes
});

if (!('token' in localStorage)) {
    asvcRouter.push({name: 'login', params: {nextUrl: asvcRouter.currentRoute.fullPath}});
}

// Intercept all unauthorized requests and redirect the user to ask for authentication
asvcRouter.beforeEach((to, from, next) => {
    if ('token' in localStorage || to.matched.some(record => record.name === 'login')) {
        next();
    } else {
        next({
            name: 'login',
            params: {nextUrl: to.fullPath}
        });
    }
});

export default asvcRouter;