<template>
  <div>
    <h1>
      <button
        type="button"
        v-if="showCreate"
        @click="showCreate = false"
        class="btn btn-circle btn-outline-secondary"
      >
        <strong>⨯</strong>
      </button>
      <button
        type="button"
        v-else
        @click="showCreate = true"
        class="btn btn-circle btn-outline-secondary"
      >
        <strong>+</strong>
      </button>
      Mailbox Accounts
      <span class="badge bg-secondary">{{ groupCountFormatted }}</span>
    </h1>

    <router-view @group-deleted="onGroupDelete"></router-view>

    <group-create
      v-if="showCreate"
      @uid-generated="onUidGenerated"
      @group-created="onGroupCreate"
    ></group-create>
    <group-list
      ref="groupList"
      @mailbox-loaded="groupCount = $event"
    ></group-list>
  </div>
</template>

<script>
"use strict";

import { createRouter, createWebHashHistory } from "vue-router";

import GroupCreate from "./GroupCreate";
import GroupList from "./GroupList";

export default {
  components: {
    GroupCreate,
    GroupList,
  },
  data: function () {
    return {
      groupCount: null, // ...
      showCreate: false,
    };
  },
  computed: {
    groupCountFormatted() {
      return this.groupCount === null
        ? "…"
        : new Intl.NumberFormat().format(this.groupCount);
    },
  },
  methods: {
    onGroupDelete: function (uid) {
      this.$refs.groupList.removeGroup(uid);
      this.groupCount--;

      this.$router.push("/");
    },
    onGroupCreate: function (uid, displayName) {
      this.showCreate = false;
      this.$refs.groupList.addGroup(uid, displayName);
      this.groupCount++;

      this.$router.push(`/groups/${uid}`);
    },
    onUidGenerated: function (uid) {
      this.$refs.groupList.searchTerm = uid;
    },
  },
};
</script>