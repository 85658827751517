<template>
  <div>
    <div v-if="loadError" class="alert alert-danger" role="alert">
      💥 Failed to load the user entries from the backend:
      <strong>{{ loadError }}</strong>
    </div>
    <div v-else>
      <form @submit.prevent class="search">
        <div class="form-group">
          <label for="searchTerm"
            >Search for a group account by name or UID:</label
          >
          <input
            type="text"
            v-model="searchTerm"
            class="form-control"
            id="searchTerm"
            placeholder="e.g. blicke"
            :autofocus="'autofocus'"
            aria-describedby="groupHelp"
          />
        </div>
      </form>

      <div v-if="isLoading" class="m-3">
        <span class="spinner-border" role="status" aria-hidden="true"></span>
      </div>
      <template v-else>
        <div v-if="Object.keys(groups).length === 0">∅</div>
        <table v-else class="table table-hover table-striped mt-1">
          <thead class="sticky-top">
            <tr>
              <th scope="col">uid</th>
              <th scope="col">Name</th>
            </tr>
          </thead>
          <tbody>
            <tr
              style="cursor: pointer"
              v-for="(name, uid) in groups"
              :key="uid"
              @click="showDetails(uid)"
            >
              <td>{{ uid }}</td>
              <td>{{ name }}</td>
            </tr>
          </tbody>
        </table>
      </template>
    </div>
  </div>
</template>

<style scoped>
.search {
  margin-bottom: 10px;
}
</style>

<script>
"use strict";
import axios from "axios";

export default {
  data() {
    return {
      isLoading: true,
      loadError: null,
      searchTerm: "",
      groupsAll: [],
      spinner: true,
    };
  },
  computed: {
    groups: function () {
      let result = {};
      let searchTerm = this.searchTerm.toLowerCase();

      for (let [index, value] of Object.entries(this.groupsAll)) {
        let found =
          value[0].toLowerCase().includes(searchTerm) ||
          value[1].toLowerCase().includes(searchTerm);
        if (found) {
          result[value[0]] = value[1];
        }
      }

      return result;
    },
  },
  methods: {
    addGroup: function (uid, displayName) {
      this.groupsAll[uid] = displayName;
    },
    removeGroup: function (uid) {
      this.groupsAll = this.groupsAll.filter((i) => i[0].localeCompare(uid));
    },
    showDetails: function (uid) {
      this.$router.push({ name: "group", params: { uid: uid } });
    },
  },
  mounted() {
    axios
      .get("groups")
      .then(
        (response) => {
          // Array of array: [0]: uid, [1]: name
          this.groupsAll = Object.entries(response.data).sort((a, b) =>
            a[1].localeCompare(b[1])
          );
          this.$emit("mailbox-loaded", Object.keys(response.data).length);
        },
        (error) => {
          this.loadError = error.message;
        }
      )
      .finally(() => (this.isLoading = false));
  },
};
</script>