<template>
  <div id="user-create-form">
    <form
      method="POST"
      class="align-middle col-md-8"
      @submit.prevent="onSubmit"
    >
      <fieldset :disabled="isCreating">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="givenName">Given name</label>
              <input
                type="text"
                v-model="payload.givenName"
                id="givenName"
                class="form-control"
                minlength="2"
                required="true"
                placeholder="Karl"
                autocomplete="given-name"
              />
            </div>
            <div class="form-group">
              <label for="surname">Surname</label>
              <input
                type="text"
                v-model="payload.surname"
                id="surname"
                class="form-control"
                minlength="2"
                required="true"
                placeholder="Karlsen"
                autocomplete="family-name"
              />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="email">Private email address</label>
              <input
                id="email"
                class="form-control"
                type="email"
                minlength="6"
                required="true"
                v-model="payload.privateEmail"
                placeholder="user@posteo.de"
              />
              <small class="form-text text-muted"
                >This is required to allow self-service password reset.</small
              >
            </div>
            <div class="form-group">
              <label for="password">Password</label>
              <input
                type="password"
                v-model="payload.password"
                id="password"
                class="form-control"
                :class="{ 'is-invalid': passwordIsInvalid }"
                minlength="6"
                required="true"
                autocomplete="new-password"
                :title="passwordWarning"
                pattern="[^:]+"
              />
              <div class="progress">
                <div
                  class="progress-bar"
                  :class="passwordClass"
                  role="progressbar"
                  :aria-valuenow="passwordStrength"
                  :style="{ width: passwordStrength * 24 + 4 + '%' }"
                  aria-valuemin="0"
                  aria-valuemax="11"
                ></div>
              </div>
              <div class="invalid-feedback" display="passwordIsInvalid">
                Due to external limitations the colon (:) isn't allowed.
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="uid">Generated UID</label>
          <input
            id="uid"
            class="form-control"
            type="text"
            v-model="uid"
            placeholder="karl.karlsen"
            readonly
          />
          <small class="form-text text-muted"
            >This is the short handle for user identification throughout all
            services … except email.</small
          >
        </div>
        <button type="submit" class="btn btn-primary">
          <span v-if="!isCreating">Create</span>
          <span v-else>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Loading...
          </span>
        </button>
      </fieldset>
    </form>

    <div v-if="requestError" class="alert alert-danger mt-1" role="alert">
      {{ requestError }}
    </div>
    <hr />
  </div>
</template>

<script>
"use strict";

import axios from "axios";
import zxcvbn from "zxcvbn";

let cssClasses = {
  0: "bg-danger",
  1: "bg-danger",
  2: "bg-warning",
  3: "bg-warning",
  4: "bg-success",
};

export default {
  data() {
    return {
      uid: "",
      passwordStrength: 0,
      passwordClass: "bg-danger",
      passwordWarning: "",
      payload: {
        givenName: "",
        surname: "",
        password: "",
        privateEmail: "",
      },
      isCreating: false,
      requestError: null,
    };
  },
  computed: {
    passwordIsInvalid: function () {
      return this.payload.password.includes(":");
    },
  },
  watch: {
    "payload.givenName": function () {
      if (this.payload.givenName !== "" && this.payload.surname !== "") {
        this.requestUid();
      }
    },
    "payload.surname": function () {
      if (this.payload.givenName !== "" && this.payload.surname !== "") {
        this.requestUid();
      }
    },
    "payload.password": function () {
      let result = zxcvbn(this.payload.password, [
        "ecg",
        "gwö",
        "ecogood",
        "gemeinwohl",
      ]);

      this.passwordStrength = result.score;
      this.passwordClass = cssClasses[result.score];
      this.passwordWarning = result.feedback.warning;
    },
  },
  methods: {
    requestUid: function () {
      let givenName = encodeURIComponent(this.payload.givenName);
      let surname = encodeURIComponent(this.payload.surname);

      axios.get(`users/uid/${givenName}/${surname}`).then(
        (resp) => {
          this.uid = resp.data;
          this.$emit("uid-generated", this.uid);
        },
        (resp) => {
          console.error("error", resp);
        }
      );
    },
    onSubmit: function (event) {
      this.isCreating = true;

      axios
        .post("users", this.payload)
        .then(
          (response) => {
            let uid = response.data.uid;
            let displayName = response.data.displayName;
            this.$emit("user-created", uid, displayName);
            this.requestError = null;
          },
          (error) => {
            this.requestError =
              error.response.status === 400
                ? error.response.data
                : "Unknown error!";

            console.error("error", error);
          }
        )
        .finally(() => {
          this.isCreating = false;
        });
    },
  },
};
</script>
