<template>
  <div>
    <div class="login-card">
      <div class="d-flex justify-content-center">
        <div class="brand_logo_container">
          <img src="ecg.png" class="brand_logo" alt="ECG logo" />
        </div>
      </div>
      <div class="d-flex justify-content-center form_container">
        <form @submit.prevent="onSubmit">
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">👤</span>
            <input
              type="text"
              v-model="uid"
              required
              class="form-control input_user"
              :autofocus="'autofocus'"
              placeholder="firstname.lastname"
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>
          <div class="input-group mb-5">
            <span class="input-group-text" id="basic-addon2">🔑</span>
            <input
              type="password"
              v-model="password"
              required
              class="form-control input_pass"
              aria-label="Password"
              aria-describedby="basic-addon2"
            />
          </div>
          <div class="d-flex justify-content-center">
            <button type="submit" name="button" class="btn login_btn">
              Login
            </button>
          </div>
        </form>
      </div>

      <div class="mt-4">
        <div class="d-flex justify-content-center links">
          <a href="https://ldapadmin.ecogood.org/passwordreset" target="_blank"
            >Forgot your password?</a
          >
        </div>
      </div>
    </div>

    <div v-if="loginError" class="alert alert-danger login-error" role="alert">
      <p>💥 Failed to login:</p>
      <strong>{{ loginError }}</strong>
    </div>
  </div>
</template>

<script>
"use strict";

import axios from "axios";

export default {
  data() {
    return {
      uid: "",
      password: "",
      loginError: null,
    };
  },
  methods: {
    onSubmit() {
      if (this.password.length > 0) {
        axios
          .post(
            "auth",
            {},
            {
              auth: {
                username: this.uid,
                password: this.password,
              },
            }
          )
          .then(
            (response) => {
              localStorage.setItem("token", response.data);
              this.$emit("loggedIn");

              if (
                "nextUrl" in this.$route.params &&
                this.$route.params.nextUrl !== "undefined"
              ) {
                this.$router.push(this.$route.params.nextUrl);
              } else {
                this.$router.push("/");
              }
            },
            (error) => {
              this.loadError = error.message;
              this.loginError = error.message;
            }
          );
      }
    },
  },
};
</script>

<style>
.login-card {
  height: 400px;
  width: 350px;
  margin: 100px auto 20px auto;
  background: #049ea6;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}
.login-card .brand_logo_container {
  position: absolute;
  height: 170px;
  width: 170px;
  top: -75px;
  border-radius: 50%;
  background-color: #049ea6;
  padding: 4px;
  text-align: center;
  border: 6px solid #fff;
}
.login-card .brand_logo {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  border: 2px solid white;
  background-color: #fff;
}

.login-card .form_container {
  margin-top: 100px;
}

.login-card .input-group-text {
  background-color: #fff;
}

.login-card .input_user,
.login-card .input_pass:focus {
  box-shadow: none;
  outline: 0;
}

.login-card .login_btn {
  width: 10rem;
  background-color: #889e33;
  color: white;
  border: 2px solid #fff;
}
.login-card .login_btn:focus {
  box-shadow: none !important;
  outline: 0px !important;
}

.login-card .links a {
  color: #fff;
}

.login-error {
  width: 350px;
  margin: 10px auto;
}
</style>
